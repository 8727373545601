/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import locale_en from "react-intl/locale-data/en"
import locale_fr from "react-intl/locale-data/fr"
import locale_es from "react-intl/locale-data/es"
import { addLocaleData } from "react-intl"

import wrapRedux from "./src/wrapRedux"
import wrapIntl from "./src/wrapIntl"

import "./src/global.css"

addLocaleData(locale_en)
addLocaleData(locale_fr)
addLocaleData(locale_es)

export const wrapRootElement = wrapRedux
export const wrapPageElement = wrapIntl
