import CONSTANTS from "../constants"

const start = new Date()
start.setDate(start.getDate() + 1)
if (start.getHours() >= CONSTANTS.maxBookingTime) {
  start.setDate(start.getDate() + 1)
}

const suggested = new Date()
suggested.setDate(suggested.getDate() + 2)

const langs = ["fr", "en", "es"]

let browserLanguage = "fr" // SSR defaults to FR for better SEO
if (typeof window !== "undefined" && window.navigator) {
  browserLanguage = (navigator.language || navigator.userLanguage || "en").substr(0, 2).toLowerCase()
}

const initialState = {
  lang: langs.includes(browserLanguage) ? browserLanguage : "en",
  loading: true,
  calendar: {},
  month: Math.min(CONSTANTS.maxMonth, Math.max(CONSTANTS.minMonth, suggested.getMonth())),
}

export default (state = initialState, action = null) => {
  switch (action.type) {
    case "LANG":
      return {
        ...state,
        lang: action.lang,
      }
    case "CALENDAR":
      return {
        ...state,
        loading: false,
        calendar: action.calendar || {},
      }
    case "MONTH":
      return {
        ...state,
        month: Math.min(CONSTANTS.maxMonth, Math.max(action.month, CONSTANTS.minMonth, start.getMonth())),
      }
    default:
      return state
  }
}
