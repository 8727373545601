// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-js": () => import("./../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-calendar-js": () => import("./../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-legal-js": () => import("./../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-rafting-saint-lary-js": () => import("./../src/pages/rafting-saint-lary.js" /* webpackChunkName: "component---src-pages-rafting-saint-lary-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

