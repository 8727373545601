import { db } from "../helpers/firebase"
import { mode } from "../helpers/api"

const now = new Date()
const year = now.getFullYear()

export default () => {
  return (dispatch) => {
    db.ref(mode + "/calendar/" + year).on(
      "value",
      (snapshot) => {
        dispatch({
          type: "CALENDAR",
          calendar: snapshot.val(),
        })
      },
      (error) => {
        if (typeof Rollbar !== "undefined") {
          Rollbar.error(error) /*global Rollbar:false*/
        } else {
          console.error(error) // eslint-disable-line no-console
        }
      }
    )
  }
}
