import CONSTANTS from "../constants"

const hostname = typeof window === "undefined" ? CONSTANTS.hostname : window.location.hostname

// [partner|www].lapagaiedaure.com
const hostparts = hostname.split(".")
export const partner = hostparts.length > 2 && hostparts[0] !== "www" ? hostparts[0] : null

export const mode = process.env.GATSBY_STRIPE.includes("_test_") ? "test" : "live"

const post = (route, params) => {
  params.mode = mode
  if (partner) {
    params.partner = partner
  }
  return fetch(process.env.GATSBY_API + "/" + route, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
    .catch(() => {
      throw new Error("fetch")
    })
    .then((response) => {
      if (response.status >= 500) {
        throw new Error("server")
      }
      // if status >= 400, the error will be in the returned JSON and is handled by the component
      return response.json()
    })
    .then((response) => {
      if (response.error) {
        throw new Error(response.error)
      }
      return response
    })
}

export default { post }
