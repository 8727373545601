import React from "react"
import { Provider } from "react-redux"
import { createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { createLogger } from "redux-logger"

import reducers from "./reducers"
import listenCalendar from "./actions/listenCalendar"

const rootReducer = combineReducers(reducers)
let store
if (process.env.NODE_ENV === "development") {
  const logger = createLogger({
    errorTransformer: (error) => {
      console.error(error) // eslint-disable-line no-console
      return error
    },
    collapsed: true,
  })
  store = createStore(rootReducer, applyMiddleware(thunk, logger))
} else {
  store = createStore(rootReducer, applyMiddleware(thunk))
}

store.dispatch(listenCalendar())

const wrapper = ({ element }) => <Provider store={store}>{element}</Provider>
export default wrapper
