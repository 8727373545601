import React from "react"
import { connect } from "react-redux"
import { IntlProvider } from "react-intl"

import translations from "./translations"

const formats = {
  number: {
    money: {
      style: "currency",
      currency: "EUR",
    },
  },
  date: {
    event: {
      month: "long",
      day: "numeric",
    },
  },
}

const Wrapper = ({ element, lang }) => (
  <IntlProvider locale={lang} messages={translations[lang]} formats={formats}>
    {element}
  </IntlProvider>
)

const mapStateToProps = (state) => ({
  lang: state.app.lang,
})

const ConnectedWrapper = connect(mapStateToProps)(Wrapper)

const wrapper = ({ element }) => <ConnectedWrapper element={element} />
export default wrapper
