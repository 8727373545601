import firebase from "firebase/app"
import "firebase/database"

const app = firebase.initializeApp({
  apiKey: process.env.GATSBY_FIREBASE_KEY,
  authDomain: process.env.GATSBY_FIREBASE_ID + ".firebaseapp.com",
  databaseURL: `https://${process.env.GATSBY_FIREBASE_ID}.firebaseio.com`,
  projectId: process.env.GATSBY_FIREBASE_ID,
  storageBucket: process.env.GATSBY_FIREBASE_ID + ".appspot.com",
  messagingSenderId: process.env.GATSBY_FIREBASE_SENDER,
})

export const db = app.database()
