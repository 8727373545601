const CONSTANTS = {
  minMonth: 1,
  maxMonth: 7,
  maxMonthForFullAfternoon: 6,
  maxPeople: 16,
  maxBookingTime: 21,
  prices: {
    classic: 40,
    full: 50,
  },
  deposit: 0.3,
  name: "Jean Rousseau",
  email: "jean@lapagaiedaure.com",
  phone: "07 69 01 84 37",
  siret: "82884929900015",
  hostname: "www.lapagaiedaure.com",
}

export default CONSTANTS
