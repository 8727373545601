export default {
  //loading: 'Loading…',
  error_bad_request: "Invalid request.",
  error_not_found: "Item not found.",
  error_fetch: "The request could not reach its destination: please check your connection or try again later.",
  error_server: "Unexpected server error. Please try again later.",

  // Home
  classic_downhill: "Classic downhill",
  full_downhill: "Full downhill",
  pricing: "Pricing",
  more: "Infos",
  contact: "Contact",
  title: "Rafting in Saint-Lary (65)",
  description:
    "Downhill rafting is a water and environment discovery activity!\nGo down the river in an fun an easy way.\nIt's a friendly outdoor aquatic experience surrounded by nature!",
  subtitle:
    "Beware! If you book, you are responsible for any side effects that a happiness overdose could cause in your life!",
  cta: "Book now",
  classic_description:
    "Come and try an accessible and fun aquatic outdoor mountain activity.\n1h30 to 1h45 of navigation and adventures aboard this big inflatable boat through an unforgettable scenery!\nWith your family or friends, come for a rafting tour on the artery of the valley, the beautiful Neste d'Aure.",
  full_description:
    "For the afternoon, if the water level allows it, you can choose to add about 50 more minutes of pure downhill,\non a non-operated part of this beautiful river, in front of the Pyrenees!",
  duration: "Duration: {duration}",
  departure: "Meet at 2:30pm",
  departures: "Meet at 9:30am or 2:30pm",
  price: "Price: {price, number, money}",
  what_to_bring: "Gear you need to bring: your swimsuit and closed shoes. The rest is provided!",
  more_gear: "Provided gear: wet suit (full or 2-piece), life jacket, helmet, paddle.",
  more_infos: "All the gear is cleaned after each downhill.",
  instructor:
    "To combine security and fun, your instructors: Jean and Charlie, holders of a canoe & kayak BPJEPS certificate.\nThey navigate the Neste river for more than 10 years.\nThey are also rescuer and shaper at Piau-Engaly ski resort",
  legal: "Legal notices and terms of sale",

  // Calendar
  calendar_title: "Choose your date…",
  weekday1: "Monday",
  weekday2: "Tuesday",
  weekday3: "Wednesday",
  weekday4: "Thursday",
  weekday5: "Friday",
  weekday6: "Saturday",
  weekday7: "Sunday",
  morning: "Morning",
  afternoon: "Afternoon",
  spots: "{num, plural, one {One spot} other {# spots}}",
  full: "full",
  closed: "closed",
  after_season: "Sorry, the season has ended.\nSee you next year!",

  // Book
  book_title: "Confirm your booking…",
  tomorrow_alert: "Book before 9pm to navigate tomorrow!",
  total: "Total {amount, number, money}",
  deposit: "Pay now: {amount, number, money} (30% deposit)",
  remaining: "Left to be paid on the venue: {amount, number, money}",
  discount: "Click here if you have a promotional code",
  discount_placeholder: "Promo code…",
  discount_checking: "Checking…",
  discount_percent: "Including your {discount}% discount!",
  checkout_title: "Book for {people, plural, one {one person} other {# persons}}",
  checkout_description:
    "On {date, date, event} {type, select, morning {in the morning} afternoon {in the afternoon} afternoon_full {in the afternoon}}",
  checkout_label: "Pay",
  checkout_button: "Pay",
  paying: "Booking…",
  error_too_late: "Sorry, it's too late to book for tomorrow.",
  error_missing_spots: "Sorry, not enough spots available anymore for that day.",
  error_full: "Sorry, that day is full.",
  error_closed: "Sorry, we are closed on that day.",
  error_code_not_found: "This code does not exist or is not valid anymore.",

  // Thanks
  thanks_title: "Done!",
  thanks:
    "Thank you for your booking. If the payment is successful, you will receive a confirmation email with all the information.",

  // Forms
  form_name: "Full name",
  form_email: "Email address",
  form_message: "Your message",
  form_phone: "Phone number",
  form_people: "Persons",
  option_type:
    "{type, select, morning {Classic downhill — morning} afternoon {Classic downhill — afternoon} afternoon_full {Full downhill — afternoon}}",
  form_confirm: "I certify that everyone knows how to swim",
  form_referral: "How did you find us?",
  incomplete_form: "Please fill in all the fields to access checkout.",
  send: "Send",
  sent: "Your message has been sent. An answer will be provided as soon as possible.",
}
