export default {
  //loading: 'Cargando…',
  error_bad_request: "Entrada invalida.",
  error_not_found: "Elemento no encontrado.",
  error_fetch: "La solicitud no pudo llegar a su destinación. Por favor verifique su conexión o intente más tarde.",
  error_server: "Error inesperado del sistema. Por favor intente más tarde.",

  // Home
  classic_downhill: "Recorrido clásico",
  full_downhill: "Recorrido integral",
  pricing: "Precios",
  more: "Infos",
  contact: "Contacto",
  title: "Rafting en Saint-Lary (65)",
  description:
    "¡Rafting es una actividad para descubrir las aguas bravas y el medio ambiente!\nBaja el río de una manera fácil y divertida.\n¡Es una actividad acuática fácil en plena naturaleza!",
  subtitle:
    "Cuidado! Si reservas, eres el único responsable de los efectos secundarios que la sobredosis de felicidad inusual podría resultar en tu vida!",
  cta: "Reservar",
  classic_description:
    "Ven a descubrir una actividad que la montaña nos da, divertida, acuática y rodeados de naturaleza.\nDe 1h 30 a 1h 45 de recorrido tendremos aventuras a bordo de un gran bote inflable que nos cambiará el escenario!\nGran idea para compartir una gran experiencia de rafting entre familia y amigos en una de las arterias del valle, la hermosa Neste d'Aure.",
  full_description:
    "Por la tarde, si el nivel del agua lo permite, puedes escoger hacer 50 minutos más de bajada pura desde Saint-Lary,\nen una parte inexplorada de este bello río de los Pirineos!",
  duration: "Duración total: {duration}",
  departure: "Cita a las 14:30",
  departures: "Cita a las 9:30 o 14:30",
  price: "Precio: {price, number, money}",
  what_to_bring: "Material necesario: Traje de baño y zapatos cerrados. Todo lo demás va por nuestra cuenta!",
  more_gear: "Material incluído: Neopreno (integral o en 2 piezas), chaleco salvavidas, casco, pala.",
  more_infos: "Todo el equipo esta limpiado después de cada bajada",
  instructor:
    "Para combinar placer con seguridad, sus instructores: Jean y Charlie, certificados BPJEPS canoa y kayac.\nHan estado navegando en la Neste d'Aure por más de 10 años.\nTambién son socorrista y shaper en la estación de esquí de Piau-Engaly.",
  legal: "Avisos legales y condiciones generales de venta",

  // Calendar
  calendar_title: "Escoje tu fecha…",
  weekday1: "Lunes",
  weekday2: "Martes",
  weekday3: "Miércoles",
  weekday4: "Jueves",
  weekday5: "Viernes",
  weekday6: "Sábado",
  weekday7: "Domingo",
  morning: "Mañana",
  afternoon: "Tarde",
  spots: "{num, plural, one {Un lugar} other {# lugares}}",
  full: "lleno",
  closed: "descanso",
  after_season: "La temporada se acabó.\nHasta el próximo año!",

  // Book
  book_title: "Confirma tu reservación…",
  tomorrow_alert: "Reserva antes de las 9 de la noche para pasear mañana!",
  total: "Total {amount, number, money}",
  deposit: "Paga ahora {amount, number, money} (30% de depósito)",
  remaining: "A pagar en el lugar: {amount, number, money}",
  discount: "Haz clic aquí si tienes un código de promoción",
  discount_placeholder: "Código de promoción…",
  discount_checking: "Verificando…",
  discount_percent: "Incluyendo tu descuento de {discount}%!",
  checkout_title: "Reserva para {people, plural, one {una persona} other {# personas}}",
  checkout_description:
    "El {date, date, event} {type, select, morning {en la mañana} afternoon {en la tarde} afternoon_full {en la tarde}}",
  checkout_label: "Pagar", // X €
  checkout_button: "Pagar",
  paying: "Reservando…",
  error_too_late: "Ups, es muy tarde para reservar mañana.",
  error_missing_spots: "Ups, no hay suficientes lugares disponibles para ese día.",
  error_full: "Ups, ese día esta lleno.",
  error_closed: "Ups, estamos descansando ese día.",
  error_code_not_found: "Este código no existe o ya no es válido.",

  // Thanks
  thanks_title: "Listo!",
  thanks:
    "Gracias por tu reservación. Una confirmación ha sido enviada a tu correo electrónico con toda la información.",

  // Forms
  form_name: "Nombre completo",
  form_email: "Correo electrónico",
  form_message: "Escribe tu mensaje",
  form_phone: "Número telefónico",
  form_people: "Cuántas personas",
  option_type:
    "{type, select, morning {Recorrido clásico por la mañana} afternoon {Recorrido clásico por la tarde} afternoon_full {Recorrido integral por la tarde}}",
  form_confirm: "Garantizo que todos sabemos nadar",
  form_referral: "¿Cómo nos encontraste?",
  incomplete_form: "Por favor llena todos los espacios para completar la reservación.",
  send: "Enviar",
  sent: "Tu mensaje ha sido enviado. Recibirás una respuesta muy pronto.",
}
