export default {
  //loading: 'Chargement…',
  error_bad_request: "Requête invalide.",
  error_not_found: "Élément introuvable.",
  error_fetch: "La requête n’a pas pu aboutir : veuillez vérifier votre connexion ou réessayer plus tard.",
  error_server: "Le serveur a retourné une erreur inattendue. Veuillez réessayer plus tard.",

  // Home
  classic_downhill: "Parcours classique",
  full_downhill: "Parcours intégral",
  pricing: "Tarifs",
  more: "Infos",
  contact: "Contact",
  title: "Rafting à Saint-Lary (65)",
  description:
    "Le rafting est une activité de découverte de l’eau vive et de l’environnement\u00A0!\nDescendez facilement et de manière ludique des portions de rivières difficile à pratiquer (pour des débutants), en kayak ou canoë traditionnel.\nC’est une expérience aquatique conviviale en pleine nature\u00A0!",
  subtitle:
    "Attention\u00A0! Si vous réservez vous êtes seuls responsables des effets secondaires qu’une surdose inhabituelle de bonheur pourrait engendrer dans votre vie\u00A0!",
  cta: "Réserver",
  classic_description:
    "Venez tester le rafting en vallée d’Aure, une activité de montagne accessible, ludique, aquatique et en pleine nature.\n1h30 à 1h45 de navigation et péripéties à bord de ce gros bateau gonflable pour un dépaysement total\u00A0!\nEn famille ou entre amis, venez faire un tour de rafting sur l’artère de la vallée, la belle Neste d’Aure.",
  full_description:
    "Pour l’après midi, si le niveau d’eau le permet, vous pouvez choisir de faire environ 50 minutes supplémentaires de descente pure\nen partant directement de St Lary, sur une portion inexploitée de cette superbe rivière pyrénéenne\u00A0!",
  duration: "Durée totale : {duration}",
  departure: "RDV à 14h30",
  departures: "RDV à 9h30 ou 14h30",
  price: "Tarif : {price, number, money}",
  what_to_bring:
    "Matériel à prévoir par vos soins : maillot de bain et chaussures fermées. Tout le reste est fourni\u00A0!",
  more_gear: "Matériel inclus : combinaison (intégrale ou 2 pièces), gilet de sauvetage, casque, pagaie.",
  more_infos: "Tout le matériel est nettoyé après chaque descente\u00A0!",
  instructor:
    "Pour lier l’agréable à la sécurité, vos moniteurs : Jean et Charlie, diplômés BPJEPS canoë-kayak et disciplines associées.\nIls naviguent depuis plus de 10 ans sur la Neste d’Aure.\nIls sont aussi Pisteur-secouriste et shaper à la station de ski de Piau-Engaly.",
  legal: "Mentions légales et conditions générales de vente",

  // Calendar
  calendar_title: "Choisissez une date…",
  weekday1: "lundi",
  weekday2: "mardi",
  weekday3: "mercredi",
  weekday4: "jeudi",
  weekday5: "vendredi",
  weekday6: "samedi",
  weekday7: "dimanche",
  morning: "Matin",
  afternoon: "Après-midi",
  spots: "{num, plural, one {Une place} other {# places}}",
  full: "complet",
  closed: "fermé",
  after_season: "Désolé, la saison est terminée.\nÀ l’année prochaine\u00A0!",

  // Book
  book_title: "Confirmez votre réservation…",
  tomorrow_alert: "Réservez avant 21h pour pouvoir naviguer demain\u00A0!",
  total: "Total : {amount, number, money}",
  deposit: "À payer : 30% d’accompte soit {amount, number, money}",
  remaining: "Reste à payer sur place : {amount, number, money}",
  discount: "Cliquez ici si vous avez un code promo",
  discount_placeholder: "Code promo…",
  discount_checking: "Vérification…",
  discount_percent: "Dont vos {discount}% de réduction\u00A0!",
  checkout_title: "Réserver pour {people, plural, one {une personne} other {# personnes}}",
  checkout_description:
    "Le {date, date, event} pour {type, select, morning {le matin} afternoon {l’après-midi} afternoon_full {l’après-midi}}",
  checkout_label: "Payer",
  checkout_button: "Payer",
  paying: "Réservation…",
  error_too_late: "Désolé, il est trop tard pour réserver pour cette journée.",
  error_missing_spots: "Désolé, il n’y a plus assez de place.",
  error_full: "Désolé, cette journée est complète.",
  error_closed: "Désolé, nous sommes fermés ce jour là.",
  error_code_not_found: "Ce code n’existe pas ou n’est plus valide.",

  // Thanks
  thanks_title: "Voilà\u00A0!",
  thanks:
    "Merci pour votre réservation. Si votre paiement est validé, vous allez recevoir une confirmation par e-mail avec toutes les informations.",

  // Forms
  form_name: "Prénom + Nom",
  form_email: "Adresse e-mail",
  form_message: "Votre message",
  form_phone: "Téléphone",
  form_people: "Personnes",
  option_type:
    "{type, select, morning {Parcours classique, le matin} afternoon {Parcours classique, l’après-midi} afternoon_full {Parcours intégral, l’après-midi}}",
  form_confirm: "Je certifie que tout le monde sait nager",
  form_referral: "Comment nous avez-vous connu ?",
  incomplete_form: "Veuillez compléter le formulaire pour accéder au paiement.",
  send: "Envoyer",
  sent: "Votre message a été envoyé. Une réponse vous sera faite dans les plus brefs délais.",
}
